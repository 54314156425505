import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

/**
 * trade 交易 tab 类型
 */
export enum SideType {
    /** 做多 */
    Long,
    /** 做空 */
    Short
}
/** tab 选项属性 */
export interface ISideTabItem {
    /** 编码 */
    id: number
    /** 类型 */
    type: SideType
    /** 名称 */
    name: string
}
/** 交易trade tablist */
const TradeSideTabList: ISideTabItem[] = [
    {
        id: 1,
        type: SideType.Long,
        name: "LONG"
    },
    {
        id: 2,
        type: SideType.Short,
        name: "SHORT"
    }
]



/** staketab  标签属性 */
export interface ITradeSideTabProps {
    /** 类型 */
    type?: number
    /**当前选中的type*/
    currentType: SideType
    /** 切换标签 */
    onChangeTab: (type: SideType) => void
}

/** 交易side 类型 */
export const TradeSideTab: FC<ITradeSideTabProps> = ({ currentType, onChangeTab, type = 1 }) => {
    // tablist 
    const [tabList, setTabList] = useState<ISideTabItem[]>(TradeSideTabList)


    /** tab 标签切换 */
    const handleTabChange = (item: any) => {
        onChangeTab && onChangeTab(item.type)
    }
    return (
        <Wrapper className={currentType == SideType.Short ? "active" : ""}>
            {
                tabList.map(item => {
                    return (
                        <Item key={`ate_side_tab_item_${item.id}`} className={item.type == currentType ? "active" : ""} onClick={e => handleTabChange(item)}>
                            {item.name}
                        </Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 220px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #101124;
    border-radius: 20px;
    padding:5px;
    &::before{
        content: '';
        position: absolute;
        left:5px;
        top:5px;
        background-color: #37ae3d;
        width:50%;
        height:30px;
        transition: all 0.3s linear;
        border-top-left-radius: 20px;
        border-bottom-left-radius:20px ;
        border-top-right-radius: 20px;
        border-bottom-right-radius:20px;
    }
    &.active::before{
        left:calc(50% - 5px);
        border-top-left-radius: 20px;
        border-bottom-left-radius:20px ;
        border-top-right-radius: 20px;
        border-bottom-right-radius:20px ;
    }
`

const Item = styled.div`
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    color: #6a6b87;
    position:relative;
    user-select: none;
    &.active{
        color: #fefefe;
    }
`