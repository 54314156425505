/** table pageSize */
export const TABLE_PAGE_SIZE = 10
/** 固定清算费用 */
export const LIQUDAtiON_FEE = 200
/** 清仓抵押资产的千分之5 */
export const LIQUDATION_DESPOSIT_FEE = 5
export const LIQUDATION_DESPOSIT_RATIO = 1000

/** 收藏交易localstorage key */
export const TRADE_HEART_KEY = "ATE_HEART_TRADE"

/** bigNumber 系数 */
export const RATIO = 1000000

/** 提高gas 费用倍数 */
export const GAS_MULTIPLE = 15

/** zero Address */
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"