import { GAS_MULTIPLE, ZERO } from 'config'
import { providers, ethers, BigNumber } from 'ethers'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { EthPriceContract, JokerContract } from 'utils'
import { defaultPlayer } from './data'
import { init, setJokerLoading } from './slice'
import { useEthBalance } from 'state/global'
import { defaultRound } from '.'
/** 状态 */
export function useJokerState() {
    return useSelector((state: AppState) => state.joker)
}

/** 获取round 信息 */
export function useGetRoundInfo(provider: providers.Web3Provider) {
    return async () => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider)
            // 获取轮次信息
            const res = await contract.getCurrentRoundInfo()
            const [icoNumber, roundId, keyTotal, endTime, startTime, total, lastTeamId,
                rewardAddress, rewardName, team0Amount, team1Amount, team2Amount, team3Amount,
                android
            ] = res;
            return {
                icoNumber,
                roundId,
                keyTotal,
                endTime,
                startTime,
                total,
                lastTeamId,
                rewardAddress,
                rewardName,
                team0Amount,
                team1Amount,
                team2Amount,
                team3Amount,
                android
            }
        } catch (ex) {
            console.log("getRoundInfo error:", ex)
            return defaultRound
        }
    }
}
/**
 * 一定数量的keys 值多少eth
 * @param provider 
 * @returns 
 */
export function useGetKeysEthPrice(provider: providers.Web3Provider) {
    return async (amount: number) => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider)
            return await contract.iWantXJokers(amount)
        } catch (error) {
            console.log("useGetKeysEthPrice error", error)
            return ZERO
        }
    }
}

/**
 * 一定数量的eth值多少keys
 * @param provider 
 * @returns 
 */
export function useGetEthKeysPrice(provider: providers.Web3Provider) {
    /**
     * 一定数量的eth值多少keys
     * @param roundId {BigNumber} 轮次id
     * @param amount {string}  输入的eth 的数量
     */
    return async (roundId: BigNumber, amount: string) => {
        try {
            const bigValue = ethers.utils.parseEther(amount)
            // joker 合约对象
            const contract = JokerContract(provider)
            return await contract.calcJokersReceived(roundId, bigValue)
        } catch (error) {
            console.log("useGetEthKeysPrice error", error)
            return ZERO
        }
    }
}

/** 获取当个key 的购买价格 */
export function useGetKeyBuyPrice(provider: providers.Web3Provider) {
    return async () => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider)
            const res = await contract.getBuyPrice()
            return res
        } catch (ex) {
            console.log("getRoundInfo error:", ex)
            return ZERO
        }
    }
}


/** 获取player 信息 */
export function useGetPlayerInfo(provider: providers.Web3Provider) {
    const getKeysEth = useGetKeysEthPrice(provider)
    /** 获取player 信息 */
    return async (account: string) => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider)
            const res = await contract.getPlayerInfoByAddress(account)
            let [id, name, keyAmount, reward, keyReward, referral, ethAmount] = res;
            /** 收益 */
            const [rewards, keyEth] = await Promise.all([
                contract.getPlayerVaults(id),
                getKeysEth(keyAmount)
            ]);
            const [rward, keyward, refward] = rewards
            return {
                id,
                name,
                keyAmount,
                reward: rward,
                keyReward: keyward,
                referral: refward,
                ethAmount,
                keyEthPrice: keyEth
            }
        } catch (ex) {
            console.log("useGetPlayerInfo error:", ex)
            return defaultPlayer
        }
    }
}

/** 初始化Init */
export function useInitJoker(provider: providers.Web3Provider) {
    const dispatch = useDispatch()
    const getBalance = useEthBalance(provider)
    const getRoundInfo = useGetRoundInfo(provider)
    const getPlayerInfo = useGetPlayerInfo(provider)
    return async (account: string) => {
        dispatch(setJokerLoading(true))

        try {
            const ethPriceContract = EthPriceContract()
            const keyContract = JokerContract(provider)
            const [round, player, ethBalance, roundStatus, roundData] = await Promise.all([
                getRoundInfo(),
                getPlayerInfo(account),
                getBalance(account),
                //ethPriceContract.latestRoundData(),
                keyContract.findRandOk(),
                ethPriceContract.latestRoundData(),
            ])
            const status = Number(roundStatus)
            let roundNum = ZERO
            if(status==5){
                roundNum= await keyContract.roundDrawCode_(round.roundId)
            }
            const { answer } = roundData
            dispatch(init({ round, player, ethBalance, status:status , ethPrice: answer,roundNum }))
            dispatch(setJokerLoading(false))
        } catch (ex) {
            console.log("useInitJoker error:", ex)
            dispatch(setJokerLoading(false))
        }
    }
}

/** 购买 */
export function useBuyKey(provider: providers.Web3Provider) {
    /** 初始化 */
    const onInit = useInitJoker(provider)
    /** 
     * 购买
     * @param account {string} 用户钱包地址
     * @param amount {string} eth 数量
     * @param teamId {number} 团队id
     * @param referral {string} 邀约地址
     */
    return async (account: string, amount: string, teamId: number, referral: string) => {
        try {
            // 将输入的eth 数量转出大数
            const bigValue = ethers.utils.parseEther(amount)
            // key 合约对象
            const contract = JokerContract(provider.getSigner())
            // eth是链上基础代币，数量需要通过value 的方式传递
            const gas = await contract.estimateGas.buyXaddr(referral, teamId, { value: bigValue });
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.buyXaddr(referral, teamId, { gasLimit, value: bigValue });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useBuyKey error:", ex)
            return false
        }
    }
}

/** 领取奖励 */
export function useWithdraw(provider: providers.Web3Provider) {
    /** 初始化 */
    const onInit = useInitJoker(provider)
    /** 
     * 提取奖励 
     * @param account {string} 用户地址
     * */
    return async (account: string) => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider.getSigner())
            // 预计使用gas 数量
            const gas = await contract.estimateGas.withdraw();
            // 提升gas 
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.withdraw({ gasLimit });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useWithdraw error:", ex)
            return false
        }
    }
}

/** 开奖 */
export function useDrawWinning(provider: providers.Web3Provider) {
    /** 初始化 */
    const onInit = useInitJoker(provider)
    /** 
     * 开奖 
     * @param account {string} 用户地址
     * */
    return async (account: string) => {
        try {
            // joker 合约对象
            const contract = JokerContract(provider.getSigner())
            // 预计使用gas 数量
            const gas = await contract.estimateGas.drawWinning();
            // 提升gas 
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.drawWinning({ gasLimit });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useDrawWinning error:", ex)
            return false
        }
    }
}