
import {  IButton } from "components";
import { Menus, LINKS } from "config";
import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom'
import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { shorten } from "utils";

export interface IPhoneMenu {
    onClose: () => void
    active: boolean
}

export const PhoneMenu = ({ onClose, active}: IPhoneMenu) => {
    const {account} = useWeb3React()
    // url跳转
    const router = useNavigate()
    // 获取path 路径
    const { pathname } = useLocation()
    // 跳转并关闭菜单栏
    const handleLink = (link: string) => {
        router(link)
        onClose()
    }
    // 根据pathname是否选中
    const MENUS = useMemo(() => {
        return Menus.map(item => {
            if (item.href === pathname) {
                item.acitve = true
            } else {
                item.acitve = false
            }
            return { ...item }
        })
    }, [pathname])

    /** 购买代币 */
    const handleBuyToken = () => {
    }
    return (
        <>
            <Mask onClick={onClose} />
            <Wrapper className={active ? "toggle" : ""}>
                <MenuWrap>
                    {
                        MENUS.map((item, index) => {
                            return (
                                <MenuItem active={item.acitve} onClick={e => handleLink(item.href)} key={`menu_item_phone_${item.id}`}>
                                    <LinkeName >{item.name}</LinkeName>
                                </MenuItem>
                            )
                        })
                    }
                </MenuWrap>
                <TokenButtonWrap>
                    {/* {account?<ConnectButton>{shorten(account)}</ConnectButton>:<ConnectButton>Connect Wallet</ConnectButton>} */}
                    <ConnectButton>BUY JOKER</ConnectButton>
                </TokenButtonWrap>
            </Wrapper>
        </>
    )
}

const TokenButtonWrap = styled.div`
    position: absolute;
    width:100%;
    bottom:50px;
`
const ConnectButton = styled(IButton)`
    height: 50px;
    width:230px;
  border-radius: 25px;
  border: solid 1px #242121;
  background-color: #d7014f;
`
const Mask = styled.div`
    position:fixed;
    top:90px;
    right:0;
    left:0;
    bottom:0;
    background:rgba(255,255,255,0.4);
    z-index:1000;
`
const LogoWrap = styled.div`
    box-sizing:border-box;
    padding:30px 20px;
    display: flex;
    justify-content:center;
`
const Logo = styled.img`
    height:44px;
`
const Wrapper = styled.div`
     width: 310px;
    position:fixed;
    top:90px;
    right:0;
    transition:all 0.3s;
    height: 500px;
    box-sizing:border-box;
    padding: 50px 40px;
    border: solid 2px #da0050;
    background-color: #242121;
    z-index:1001;
    &.toggle{
        right: 0px;
   }
`
const MenuWrap = styled.div`
    margin-top:0px;
    display:flex;
    flex-direction:column;
    align-items:center;
`
const MenuItem = styled.div<{ active: boolean }>`
    width: 150px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 0 40px 0 #21622f;
    border: solid 1px #000;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content: center;
    background: ${({ active }) => active ? "#da0050" : "#1e1c1c"};
`

const LinkeName = styled.div`
    font-size: 16px;
  font-weight: 500;
  color:#fff;
`
