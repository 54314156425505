import React, { lazy, ReactNode, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout,Key,P3d, Home, Airdrop,Launchpad } from 'page'


export default () => {
  return (
    <Suspense fallback={null}>
      <Router>
        <Routes>
          <Route path="" element={<Home />} /> 
          <Route path="" element={<Layout />} >
           {/*  <Route path="/joker" element={<Key />} />
            <Route path="/doll" element={<P3d />} /> */}
            <Route path="/tokenomics" element={<Launchpad />} />
           {/*  <Route path="/airdrop" element={<Airdrop />} /> */}
            <Route
              path="*"
              element={
                <div style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </div>
              }
            />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
};