import { ZERO } from 'config'
import { ICurrentRound, IUserInfo } from './types'

/** 默认round info */
export const defaultRound: ICurrentRound = {
    /** 次轮ico 的数量 */
    icoNumber: ZERO,
    /** 轮次id */
    roundId: ZERO,
    /** key 总数量 */
    keyTotal: ZERO,
    /** 开始时间 */
    startTime: ZERO,
    /** 结束时间 */
    endTime: ZERO,
    /** 本轮的资金存量 */
    total: ZERO,
    /**最后购买的团队id */
    lastTeamId: ZERO,
    /** 中奖人的地址 */
    rewardAddress: "",
    /** 中奖人的名称 */
    rewardName: "",
    /** team0 购买的数量 */
    team0Amount: ZERO,
    /** team1 购买的数量*/
    team1Amount: ZERO,
    /** team2 购买的数量 */
    team2Amount: ZERO,
    /** team3 购买的数量 */
    team3Amount: ZERO,
    /** 空头 */
    android: ZERO
}

/** 玩家信息默认值 */
export const defaultPlayer: IUserInfo = {
    /** id */
    id: ZERO,
    /** 用户名称 */
    name: "",
    /** key 的数量 */
    keyAmount: ZERO,
    keyEthPrice:ZERO,
    /** 用户48% 赚取数量 */
    reward: ZERO,
    /** key 的收益 */
    keyReward: ZERO,
    /** 用户邀约了多少 */
    referral: ZERO,
    /** 购买了多少eth */
    ethAmount: ZERO
}