import { createSlice } from '@reduxjs/toolkit'
import { ZERO } from 'config'
import { IJokerState } from './types'
import { defaultPlayer, defaultRound } from './data'
import { setLoading } from 'state/p3d'
/** 初始化状态 */
const initState: IJokerState = {
    round: defaultRound,
    ethBalance: ZERO,
    player: defaultPlayer,
    loading: false,
    status: 1,
    ethPrice: ZERO,
    roundNum:ZERO
}

/** Slice */
const Slice = createSlice({
    name: "nft/joker",
    initialState: initState,
    reducers: {
        /** 初始化状态 */
        init: (state, action) => {
            const { round, ethBalance, player, ethPrice, status } = action.payload
            state.ethBalance = ethBalance
            state.round = round
            state.player = player
            state.status = status
            state.ethPrice = ethPrice
        },
        /** 加载loading */
        setJokerLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

/** reducer */
export const JokerReducer = Slice.reducer
/** action */
export const { init, setJokerLoading } = Slice.actions