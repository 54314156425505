import { Contract, providers, Signer } from 'ethers'
import { CONTRACT_ADDRESS } from 'config'
import { ERC20_ABI, MULTICALL_ABI, KEY_ABI, P3D_ABI, ETH_PRICE_ABI } from 'abi'
/** provider or Signer 类型 */
export type ProviderOrSigner = providers.Provider | Signer



/** 获取ERC20 合约对象 */
export const Erc20Contract = (address: string, provider: ProviderOrSigner) => {
    const contract = new Contract(address, ERC20_ABI, provider)
    return contract
}

/** multicall 合约对象 */
export const MulticallContract = (provider: ProviderOrSigner) => {
    const contract = new Contract(CONTRACT_ADDRESS.multicall, MULTICALL_ABI, provider)
    return contract
}

/** joker 合约对象 */
export const JokerContract = (provider: ProviderOrSigner) => {
    const contract = new Contract(CONTRACT_ADDRESS.joker, KEY_ABI, provider)
    return contract
}

/** p3d 合约对象 */
export const P3dContract = (provider: ProviderOrSigner) => {
    const contract = new Contract(CONTRACT_ADDRESS.p3d, P3D_ABI, provider)
    return contract
}

/** eth price 合约对象 */
export const EthPriceContract = (provider?: ProviderOrSigner) => {
    let p
    if (provider == undefined) {
        p = new providers.JsonRpcProvider("https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7")
    } else {
        p = provider
    }
    const contract = new Contract(CONTRACT_ADDRESS.ethPrice, ETH_PRICE_ABI, p)
    return contract
}