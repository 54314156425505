import React,{ReactNode} from 'react'
import styled from 'styled-components'

/** 行  */
export const Row = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    &:nth-last-child(1){
        margin-bottom: 0px;
    }
`
/** 区块行 */
export const BlockRow = styled.div`
    width:100%;
    box-sizing: border-box;
    height: 50px;
    padding: 10px 20px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

/** 左侧文字  */
export const ColumnLeft = styled.div`
     font-size: 14px;
     color:#888;
     line-height: 1;
`
/** 右侧  */
export const ColumnRight = styled.div`
    display: flex;
    align-items: center;
`