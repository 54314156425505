import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'


/** range props 属性 */
export interface IRangeProps {
    /** value */
    value: number
    /** 最大值 */
    max: number
    /** 最小值 */
    min: number
    /** onChange */
    onChange: (value: number) => void
    /** 步长 */
    step?: number
}

/** range 组件 */
export const Range: FC<IRangeProps> = ({ value, max, min, onChange, step = 1 }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        onChange(parseFloat(value))
    }
    const getRatio = () => {
        const total = max - min
        const ratio = ((value - min) / total) * 100
        return ratio
    }
    return (
        <Wrapper>
            <RangeInput
                value={value}
                max={max}
                min={min}
                step={step}
                bsize={getRatio()}
                onChange={handleChange}
            ></RangeInput>
            <SubWrapper>
                <SubLeft>{min}x</SubLeft>
                <SubRight>{max}x</SubRight>
            </SubWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
`
const SubWrapper = styled.div`
    position:relative;
    margin-top:4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const SubLeft = styled.div`
    font-size: 12px;
    color: #6a6b87;
    position:relative;
    left:0;
`
const SubRight = styled.div`
    font-size: 12px;
    color: #6a6b87;
    position:relative;
`

const RangeInput = styled.input.attrs(props => ({
    type: "range"
})) <{ bsize: number }>`
    -webkit-appearance: none;
    height: 3px;
    background: #265646;
    border-radius: 5px;
    background-image: linear-gradient(#26c040, #26c040);
    width:100%;
    background-repeat: no-repeat;
    background-size: ${({ bsize }) => bsize}% 100%;
    &::-webkit-slider-thumb{
        -webkit-appearance: none;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: #26c040;
        cursor: pointer;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }
    &::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
`