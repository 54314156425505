import {MASK_IMG,MASK_SELECT_IMG,DOK_IMG,DOK_SELECT_IMG,APE_IMG,APE_SELECT_IMG,ZHU_IMG,ZHU_SELECT_IMG} from 'assets'
/**
 * team 属性
 */
export interface ITeamAttribute{
    /** id */
    id:number
    /** teamId */
    teamId:number
    /** icon 默认icon */
    icon:string
    /** 选中的icon */
    selectedIcon:string
    /** 名称 */
    name:string
    /** 描述 */
    desc:string
    /** bottom */
    bottom:string
    /** 是否选中 */
    isActive:boolean
}

/** team 列表 */
export const TEAM_LIST:ITeamAttribute[]=[
    {
        id:1,
        teamId:0,
        icon:MASK_IMG,
        selectedIcon:MASK_SELECT_IMG,
        name:"Mask",
        desc:"Feed on the greed of others",
        bottom: "+ Most ETH to port",
       
        isActive:true
    },
    {
        id:2,
        teamId:1,
        icon:DOK_IMG,
        selectedIcon:DOK_SELECT_IMG,
        name:"Dokwon",
        desc:"Stand alone. fight alone",
        bottom: "+ Maximize eth to current round ",
        isActive:true
    },
    {
        id:3,
        teamId:2,
        icon:APE_IMG,
        selectedIcon:APE_SELECT_IMG,
        name:"Ape",
        desc:"Trickle down divinamics",
        bottom: "+ Most dividends",
        isActive:true
    },
    {
        id:4,
        teamId:3,
        icon:ZHU_IMG,
        selectedIcon:ZHU_SELECT_IMG,
        name:"ZhuSu",
        desc:"Break upwards never stagnate.",
        bottom: "+ Balanced distribution",
        
        isActive:true
    }
]