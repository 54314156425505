import { lazy } from 'react';

export const Layout = lazy(() => import('./layout'))

export const Key = lazy(() => import("./key"))

export const P3d = lazy(() => import("./p3d"))

export const Home = lazy(() => import('./home'))

export const Airdrop= lazy(() => import('./airdrop'))

export const Launchpad= lazy(() => import('./launchpad'))
