import React, { ReactNode } from 'react'
import styled from 'styled-components'



export const Card = ({ children, height = "auto" }: { children?: ReactNode, height?: string }) => {
    return (
        <Wrapper height={height}>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div<{ height: string }>`
    width: 100%;
    height: ${({ height }) => height};
    box-sizing: border-box;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #f0f0f0;
    background-color: #fff;
    @media screen and (max-width:960px){
        width: 100%;
        height: auto;
        padding: 20px;
    }
`