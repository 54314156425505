import { ZERO } from 'config'
import { providers } from 'ethers'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state'
import { initGlogalState } from './slice'
const key = "FomoJokerDoll_Referral"
/** 全局状态 */
export function useGlobalState() {
    return useSelector((state: AppState) => state.global)
}

/** 初始化邀约人地址 */
export function useInitGlobalState() {
    const dispatch = useDispatch()
    /**
     * 初始化邀约人地址
     * @param account {string} 邀约人地址
     */
    return (account: string) => {
        if (account) {
            localStorage.setItem(key, account)
            dispatch(initGlogalState(account))
        } else {
            const address = localStorage.getItem(key)
            console.log("address",address)
            if (address) {
                dispatch(initGlogalState(address))
            }
        }
    }
}

/** 获取eth balance  */
export function useEthBalance(provider: providers.Web3Provider) {
    /** 获取链上基础代币 */
    return async (account: string) => {
        try {
            // eth 是链上的基础代币，不是erc20 代币
            const balance = await provider.getBalance(account)
            return balance
        } catch (error) {
            console.log("useEthBalance error:", error)
            return ZERO
        }
    }
}