import { IButton } from "components";
import { ChangeEvent, useRef, useState } from "react";
import styled from "styled-components";
import {useOnClickOutside} from 'hooks'

export const AddReferral = ({ onConfirm,onClose }: { onClose:()=>void,onConfirm: (value:string) => void }) => {
     /** modal 内容ref */
     const ref = useRef<HTMLDivElement>(null)
    const [value, setValue] = useState("")
    const [error, setError] = useState("")
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        setError("")
    }
    const handleChange = () => {
        if (value == "") {
            setError("Invalid code")
            return
        }
        onConfirm(value)
    }
    /** 点击事件不在menuWrap 菜单中时 */
  
    useOnClickOutside<HTMLDivElement>(ref, onClose)
    return (
        <Wrapper ref={ref}>
            <Title>邀约人钱包地址</Title>
            <InputWrapper>
                <Input placeholder="Enter Address" value={value} onChange={handleOnChange}></Input>
            </InputWrapper>
            {error && <Tip>{error}</Tip>}
            <Button onClick={handleChange}>Confirm</Button>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: absolute;
    width: 330px;
    height: 270px;
    padding: 21px 20px 22px;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #000;
    background-color: #3d3939;
    top:55px;
    right:0;
    @media screen and (max-width:960px){
        width:100%;
        top:140px;
    }
`
const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color:#fff;
`
const InputWrapper = styled.div`
    height: 40px;
    width:100%;
    box-sizing: border-box;
    padding:0 15px;
    border-radius: 5px;
    border: solid 1px #000;
    background-color: #242121;
    margin-top:14px;
    display: flex;
    align-items: center;
`
const Input = styled.input`
    width:100%;
    outline: none;
    border:none;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color:#fff;
    background:transparent;
    &::placeholder{
        color:rgba(255,255,255,0.4);
    }
`
const Tip = styled.div`
    height: 40px;
    margin-top: 10px;
    padding: 11px 15px 11px 15px;
    width:100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #ffd301;
    font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffd301;
`
const Button = styled(IButton)`
     border-radius: 30px;
     height: 40px;
     margin-top:15px;
     width:100%;
     background-color: #ca074d;
`