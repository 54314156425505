import React, { FC } from 'react'
import styled from 'styled-components'

/** lp token image */
export interface IlpTokenIcon {
    /** token0 image */
    icon0: string
    /** token1 image */
    icon1: string
    /** 图标宽度 */
    width: number
}

/** lp 代币图标 */
export const LpTokenIcon: FC<IlpTokenIcon> = ({ icon0, icon1, width }) => {
    return (
        <TokenImgWrap>
            <TokenImg src={icon0} width={width}></TokenImg>
            <TokenImg1 src={icon0} width={width}></TokenImg1>
        </TokenImgWrap>
    )
}

const TokenImgWrap = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`

const TokenImg = styled.img<{ width: number }>`
    width:${({ width }) => width}px;
    height:${({ width }) => width}px;
    border-radius: 50%;
    position: relative;
`

const TokenImg1 = styled.img<{ width: number }>`
    width:${({ width }) => width}px;
    height:${({ width }) => width}px;
    border-radius: 50%;
    position: relative;
    margin-left:-11px;
`