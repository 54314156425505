import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

/**
 * stake tab 类型
 */
export enum TabType {
    left,
    rigth
}
/** tab 选项属性 */
export interface ITabItem {
    /** 编码 */
    id: number
    /** 类型 */
    type: TabType
    /** 名称 */
    name: string
}
/** 交易trade tablist */
const TradeTabList: ITabItem[] = [
    {
        id: 1,
        type: TabType.left,
        name: "Paris"
    },
    {
        id: 2,
        type: TabType.rigth,
        name: "My Favorites"
    }
]

/** position tab */
const PositionList: ITabItem[] = [
    {
        id: 1,
        type: TabType.left,
        name: "Leveraged"
    },
    {
        id: 2,
        type: TabType.rigth,
        name: "Trade"
    }
]

/** staketab  标签属性 */
export interface IStakeTabProps {
    /** 类型 */
    type?: number
    /**当前选中的type*/
    currentType: TabType
    /** 切换标签 */
    onChangeTab: (type: TabType) => void
}


export const Tab: FC<IStakeTabProps> = ({ currentType, onChangeTab, type = 1 }) => {
    // tablist 
    const [tabList, setTabList] = useState<ITabItem[]>([])

    useEffect(() => {
        if (type == 1) {
            setTabList(TradeTabList)
        } else if (type == 2) {
            setTabList(PositionList)
        }
    }, [type])
    /** tab 标签切换 */
    const handleTabChange = (item: any) => {
        onChangeTab && onChangeTab(item.type)
    }
    return (
        <Wrapper className={currentType == TabType.rigth ? "active" : ""}>
            {
                tabList.map(item => {
                    return (
                        <Item key={`ate_tab_item_${item.id}`} className={item.type == currentType ? "active" : ""} onClick={e => handleTabChange(item)}>
                            {item.name}
                        </Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 260px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #22223c;
    border-radius: 20px;
    &::before{
        content: '';
        position: absolute;
        left:0;
        top:0;
        background-color: #ffc100;
        width:50%;
        height:40px;
        transition: all 0.3s linear;
        border-top-left-radius: 20px;
        border-bottom-left-radius:20px ;
        border-top-right-radius: 20px;
        border-bottom-right-radius:20px;
    }
    &.active::before{
        left:50%;
        border-top-left-radius: 20px;
        border-bottom-left-radius:20px ;
        border-top-right-radius: 20px;
        border-bottom-right-radius:20px ;
    }
`

const Item = styled.div`
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    color: #a1a3c4;
    position:relative;
    user-select: none;
    &.active{
        color: #0b111c;
    }
`