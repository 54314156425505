import React, { useEffect, RefObject } from "react";

/** 点击不在元素内部 */
export const useOnClickOutside = <T extends HTMLElement>(ref: RefObject<T>, handle: (e: any) => void) => {
    useEffect(() => {
        /** 监听事件 */
        const listener = (event: any) => {
            const el = ref?.current
            // 如果ele 为undefined 或者el 中包含点击的元素，表示在el 这个元素范围内
            if (!el || el.contains(event.target as Node)) {
                return
            }
            // 不在返回内
            handle(event)
        }

        document.addEventListener("mousedown", listener)
        document.addEventListener("touchstart", listener)

        /** 移除事件监听，防止内存泄漏 */
        return () => {
            document.removeEventListener("mousedown", listener)
        }
    }, [ref, handle])
}