import styled from "styled-components";

/** button 基础类，如要修改继承此基础类即可  */
export const IButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    border: none;
    width: 100px;
    height: 34px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;

    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    border-radius: 17px;
    background-color: #ffc100;
    &:disabled{
        cursor:not-allowed;
        opacity: 0.4;
    }
`

/** 没有背景颜色的按钮  */
export const IButton2= styled.button`
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 20px;
    border: solid 1px #614e24;
    font-size: 14px;
    color: #ffc100;
    cursor: pointer;
    user-select: none;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
    &:hover{
        border: solid 1px #ffc100;
    }
`