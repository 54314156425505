import React from 'react'
import styled from 'styled-components'

/** 导航图片 */
export const Banner = ({ src }: { src?: string }) => {
    return (
        <Wrapper>
            {src && <Image src={src}></Image>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 1230px;
    height: 200px;
    border-radius: 5px;
    background-color: #d8d8d8;
    @media screen and (max-width:960px) {
        width:100%;
        min-height: 200px;
    }
`

const Image = styled.img`
    width: 100%;
    background-size: cover;
`
