
/**
 * menu item 类型
 */
export interface IMenuItem {
    /** 唯一标识 */
    id: number
    /** 显示名称 */
    name: string
    /** 链接 */
    href: string
    /** 是否选中状态 */
    acitve: boolean
    /** 默认图标 */
    img?: string
    /** 选中图标 */
    hoverImg?: string
}
/**
 * 导航链接
 */
export const Menus: IMenuItem[] = [
    {
        id: 1,
        name: "Home",
        href: "/",
        acitve: false,
        img: "",
        hoverImg: ''
    },
   /*  {
        id: 2,
        name: "Airdrop",
        href: "",
        acitve: false,
        img: "",
        hoverImg: ''
    }, */
    {
        id: 3,
        name: "Tokenomics",
        href: "/tokenomics",
        acitve: false,
        img: "",
        hoverImg: ''
    },
   /*  {
        id: 4,
        name: "Buy Joker",
        href: "",
        acitve: false,
        img: "",
        hoverImg: ''
    }, */
    /* {
        id: 5,
        name: "Buy Doll",
        href: "",
        acitve: false,
        img: "",
        hoverImg: ""
    }, */
   /*  {
        id: 6,
        name: "NFT",
        href: "/nft",
        acitve: false,
        img: "",
        hoverImg: ""
    } */
]