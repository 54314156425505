import {BigNumber, ethers} from 'ethers'

/** 大数0 */
export const ZERO = ethers.constants.Zero

/** 大数1 */
export const ONE = ethers.utils.parseEther('1')


/** max256 最大值 */
export const MAXUNIT256 = ethers.constants.MaxUint256



export * from './constants'
export * from './address'
export * from './chain'
export * from './connector'
export * from './token'
export * from './platform'
