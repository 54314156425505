/**
 * 合约地址
 */
export default {
    /** 小丑卡牌合约 */
    p3d: '0xa6f3850baf301066f2341bFf874f581f286496d8',
    /** p3d 合约，类型erc20 合约 */
    joker: '0xA1e62b7C20b00cB3C40126c40b969fF47919b645',
    /**
     * multicall
     */
    multicall: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
    /** eth price */
    ethPrice:"0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419"
}