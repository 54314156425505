import React,{ReactNode} from 'react'
import styled from 'styled-components'

export const Block = ({children}:{children?:ReactNode}) =>{
    return (
        <Wrapper>
            {
                children
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    box-sizing: border-box;
`