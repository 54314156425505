import { FC, ReactNode, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'
import { useOnClickOutside } from 'hooks'

/** modal 弹窗属性 */
export interface IModalProps {
    /**宽度 */
    width: number
    /**高度 */
    height: number | string
    /**关闭 */
    onClose(): void
    /** 是否显示弹窗 */
    isShow: boolean
    /** title
     * 当title 为undefined 时，不显示title
     */
    title?: ReactNode
    /** 子元素 */
    children?: ReactNode
}

/**
 * 弹窗组件
 */
export const Modal: FC<IModalProps> = ({ width, height, onClose, isShow = false, title, children }) => {
    /** modal 内容ref */
    const ref = useRef<HTMLDivElement>(null)

    // 点击外面触发事件
    const handleClickOutside = (e: any) => {
        onClose()
    }

    const Height = useMemo(() => {
        if (typeof height === "number") {
            return `${height}px`
        }
        return height;
    }, [height])
    useOnClickOutside<HTMLDivElement>(ref, handleClickOutside)

    // 不显示弹窗，返回一个null
    if (!isShow) {
        return null
    }
    return (
        <Wrapper>
            <Container ref={ref} width={width} height={Height}>
                <Header>
                    <Title>
                        {title}
                    </Title>
                    <CloseButton onClick={onClose}>
                        <X color='#fff' size={14}/>
                    </CloseButton>
                </Header>
                {children}
            </Container>
        </Wrapper>
    )
}

/* 毛玻璃效果 */
const Wrapper = styled.div`
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index: 9999;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
`
/* 弹窗具体内容 */
const Container = styled.div<{ width: number, height: string }>`
     width: ${({ width }) => width}px;
     height: ${({ height }) => height};
     border-radius: 10px;
     background-color: #1d1e34;
     box-sizing: border-box;
     display: flex;
     position: fixed;
     flex-direction: column;
     @media screen and (max-width:960px) {
         width:calc(100% - 20px);
     }
`
const Header = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    background-color: #282843;
`
const Title = styled.div`
    font-size: 16px;
    color:#fff;
`

const CloseButton = styled.div`
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color:transparent;
    position: absolute;
    right:26px;
    top:23px;
`