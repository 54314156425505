import styled from "styled-components";

/** table body */
export const TableBody = styled.div``

/** table Row */
export const TableRow = styled.div`
    display: flex;
    padding: 19px 0px;
    border-top: solid 1px #2e2f44;
    cursor: pointer;
    user-select:none;
    @media screen and (max-width:960px){
        flex-direction: column;
        
    }
`
/** table Column */
export const TableColumn = styled.div`
    flex:1;
    display: flex;
    @media screen and (max-width:960px){
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        &:nth-last-child(1){
            margin-bottom: 0px;
        }
    }
`