import styled from "styled-components";
import { FC, useMemo } from 'react'

/** 百分比集合 */
export interface IPercent {
    /** 唯一标识 */
    id: number
    /** name */
    value: number
    /** 是否选中 */
    active: boolean
}

const List: IPercent[] = [
    {
        id: 1,
        value: 25,
        active: false
    },
    {
        id: 2,
        value: 50,
        active: false
    },
    {
        id: 3,
        value: 75,
        active: false
    },
    {
        id: 4,
        value: 100,
        active: false
    }
]

/** 属性 */
export interface IPercentTab {
    /** 当前选中的百分比 */
    current: number
    /** 点击tab 切换事件 */
    onClick: (value: number) => void
    /** lkey 用于区别key */
    lkey?:string
}
/** 百分比tab button  */
export const PercentTab: FC<IPercentTab> = ({ current, onClick ,lkey}) => {
    const LIST = useMemo(() => {
        return List.map(item => {
            if (item.value === current) {
                item.active = true
            } else {
                item.active = false
            }
            return { ...item }
        })
    }, [current])

    /** tab 标签点击 */
    const handleClick =(item:IPercent)=>{
        onClick(item.value)
    }
    return (
        <Wrapper>
            {
                LIST.map(item=>{
                    return (
                        <Item onClick={e=>handleClick(item)} className={item.active?"active":""} key={`percent_item_${item.id}_${lkey}`}>{item.value}%</Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display:flex;
    align-items: center;
    width:100%;
`
const Item = styled.div`
    -webkit-tap-highlight-color:transparent;
    user-select:none;
    cursor: pointer;
    flex:1;
    height: 35px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 12px;
    color: #a1a3c4;
    line-height: 1.17;
    border-radius: 17.5px;
    border: solid 1px #3f3f55;
    &:nth-last-child(1){
        margin-right: 0px;
    }
    &.active{
        border: solid 1px #ffc100;
        color:#ffc100;
    }
`