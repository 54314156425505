import React from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'

/** 顶部网络错误提示  */
export const NetWorkTip = ({ onClose }: { onClose: () => void }) => {
    return (
        <Wrapper>
            <Container>
                <Tip>We've detected that you need to switch your wallet's network from mainnet to BNB Chain for this Dapp.</Tip>
                <CloseButton onClick={onClose}>
                    <X color='#fff' size={14}></X>
                </CloseButton>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    height: 50px;
    padding: 18px 0;
    background-color: #4f3cc8;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:960px) {
        width:100%;
        padding: 18px 20px;
    }
`
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1230px;
    @media screen and (max-width:960px) {
        width:100%;
    }
`
const Tip = styled.div`
    font-size: 12px;
    color:#fff;
`

const CloseButton = styled.div`
    user-select: none;
    cursor: pointer;
`