import styled from 'styled-components'
import { PLATFORM_LIST, DEFAULT_PLATFORM, IPlatForm } from 'config'
import { useMemo, FC } from 'react'



/** 平台table Button属性 */
export interface IPlatFormTabButton {
    /** 当前选中的平台symbol */
    current: string
    /** 选中点击事件 */
    onClick: (symbol: string) => void
}

/** 平台table Button */
export const PlatFormTabButton: FC<IPlatFormTabButton> = ({ current, onClick }) => {
    /** 设置按钮点击选中 */
    const List: IPlatForm[] = useMemo(() => {
        return PLATFORM_LIST.map(item => {
            let active=false
            // 如果当前的选项和item symbol 相等，则为选中状态
            if (item.symbol == current) {
                active = true
            } else {
                active = false
            }
            return { ...item,active }
        })
    }, [current])
    // 按钮点击事件
    const handleClick = (item: IPlatForm) => {
        onClick(item.symbol)
    }
    return (
        <Wrapper>
            {
                List.map(item => {
                    return (
                        <Item className={item.active ? "active" : ""} onClick={e => handleClick(item)} key={`platform_item_${item.id}`}>
                            <Icon src={item.icon} />
                            <Text className={item.active ? "active" : ""}>{item.name}</Text>
                        </Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    display: flex;
    align-items: center;
`
const Item = styled.div`
    height: 40px;
    padding: 0px 15px;
    border-radius: 20px;
    border: solid 1px #3f3f55;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select:none;
    margin-right: 15px;
    -webkit-tap-highlight-color:transparent;
    &.active{
        border: solid 1px #ffc100;
    }
    &:nth-last-child(1){
        margin-right: 0;
    }

`
const Icon = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right:10px;
`
const Text = styled.div`
    font-size: 14px;
    color: #a1a3c4;
    &.active{
        color:#ffc100;
    }
`