import { useMemo } from 'react'
import styled from "styled-components";
import { Menus } from 'config'
import { useLocation, useNavigate } from 'react-router-dom'

export const HeaderMenu = () => {

    const location = useLocation()
    const router = useNavigate()

    const List = useMemo(() => {
        return Menus.map(item => {
            if (location.pathname==(item.href)) {
                item.acitve = true
            } else {
                item.acitve = false
            }
            return item
        })
    }, [location])

    // 链接跳转
    const handleNavigate = (item: any) => {
        router(item.href)
    }
    return (
        <Wrapper>
            {
                List.map(item => {
                    return (
                        <Item className={item.acitve ? "active" : ""} key={`menu_item_${item.id}`} onClick={e => handleNavigate(item)}>{item.name}</Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.nav`
    list-style: none;
    display: flex;
    align-items: center;
`

const Item = styled.div`
    font-size: 14px;
    font-weight: bold;
    color:#fff;
    margin-right: 50px;
    cursor: pointer;
    &.active{
        color:#20d58f;
    }
    &:hover{
        color:#20d58f;
    }
    &:nth-last-child(1){
        margin-right: 0;
    }
`