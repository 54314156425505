import { createGlobalStyle } from 'styled-components'


export const GlobalStyle = createGlobalStyle`
    *{
        margin:0;
        padding: 0;
    }
    html,body{
        font-family: Futura-Medium;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        width:100vw;
    }
    body{
        height: 100vh;
        overflow:hidden;
        background:#000;
    }
`