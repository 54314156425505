import { Web3ReactProvider } from '@web3-react/core'
import { Provider as StateProvider } from 'react-redux'
import { getLibray } from 'utils'
import { store } from 'state'
import { ReactNode } from 'react'

export const Provider = ({ children }: { children: ReactNode }) => {
    return (
        <Web3ReactProvider getLibrary={getLibray}>
            <StateProvider store={store}>
                {children}
            </StateProvider>
        </Web3ReactProvider>
    )
}