import { Toast } from 'components'
import ReactDOM from 'react-dom'
function TocastBody(props: { content: string, interVal?: number,borderColor?:string }) {
    const div = document.createElement("div")
    document.body.appendChild(div)
    const handleClose = () => {
        if (div) {
            document.body.removeChild(div)
        }
    }
    const ToastCom = <Toast {...props} onClose={handleClose} />
    ReactDOM.render(ToastCom, div)
}
const INTERVAL = 3500
export const Message = {
    error() {
        TocastBody({ content: "Operation Failed",borderColor:"#da0050" })
    },
    success() {
        TocastBody({ content: "Operation successed" })
    },
    warning(content: string,) {
        TocastBody({ content,borderColor:"#ffd301" })
    }
    /*operationSuccess(){
        TocastBody({content:"Operation successed",title:"",bgColor:"#37ae3d",interVal:INTERVAL})
    },
    operationFailed(){
        TocastBody({content:"Operation Failed",title:"",bgColor:"#f44336",interVal:INTERVAL})
    } */
}