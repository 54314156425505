import styled from "styled-components";
import { useAuth, useOnClickOutside } from 'hooks'
import { useWeb3React } from '@web3-react/core'
import { shorten } from "utils";
import { useRef, useState } from "react";

export const ConnectButton = () => {
    /** modal 内容ref */
    const ref = useRef<HTMLDivElement>(null)
    /** 根据account 判断是否已连接钱包 */
    const { account } = useWeb3React()
    /** 链接钱包和退出钱包 */
    const [onLogin, onLogout] = useAuth()
    // 是否打开menu菜单
    const [isOpen, setIsOpen] = useState(false)
    // 打开菜单
    const handleOpenMenu = () => {
        setIsOpen(true)
    }
    /** 点击事件不在menuWrap 菜单中时 */
    const handleClickOutside = () => {
        setIsOpen(false)
    }
    useOnClickOutside<HTMLDivElement>(ref, handleClickOutside)
    return (
        <>
           
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height:100%;
    padding: 0px 12px;
    border-radius: 20px;
    border: solid 1px #44455a;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select:none;
    -webkit-tap-highlight-color:transparent;
`
const Icon = styled.img`
    width: 14px;
    height: 14px;
`
const Text = styled.div`
    margin-left:10px;
    color: #a1a3c4;
    font-size: 14px;
`
const Container = styled.div`
     width: 100%;
    height:100%;
    position: relative;
`
const ListWrapper = styled(Wrapper)`
    justify-content: space-between;
    
`
const Left = styled.div`
    flex:1;
    display: flex;
    align-items: center;
`
const Arrow = styled.img`
    width:12px;
`

const MenuWrapper = styled.div`
    width:190px;
    padding: 18px 20px 18px 20px;
    border-radius: 5px;
    background-color: #22223c;
    box-sizing: border-box;
    position: absolute;
    top:50px;
    right:0;
    @media screen and (max-width:960px){
        width:100%;
    }
`

const MenuItemWrapper = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom:15px;
    &:nth-last-child(1){
        margin-bottom:0;
    }
`