import React, { ReactNode, FC, ChangeEvent } from 'react'
import styled from 'styled-components'

/**
 * input 属性
 */
export interface InputTextProps {
    /** 值 */
    value: string,
    /** change 事件 */
    onChange: (value: string) => void,
    /** max 点击事件 */
    onMaxClick: () => void
    /** balanceof */
    balance: ReactNode
    /** placeholder */
    placeholder: string
}

/** 输入框的另一种形式 */
export const Input2: FC<InputTextProps> = ({ value, onChange, onMaxClick, balance, placeholder }) => {

    /** handle input change */
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        onChange(value)
    }
    
    return (
        <Wrapper>
            <Input type="text" value={value} onChange={handleChange} placeholder={placeholder} />
            <Right>
                {balance}
                <Max onClick={onMaxClick}>Max</Max>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    padding: 13px 15px;
    border-radius: 5px;
    border: solid 1px #ebebeb;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Input = styled.input`
    outline:none;
    border:none;
    width: 60%;
    font-size: 16px;
    font-weight: bold;
    color:#222;
    background:transparent;
    &::placeholder{
        color: #aaa;
    }
`

const Right = styled.div`
    display:flex;
    align-items: center;
`

const Max = styled.button`
    outline:none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    border-radius: 5px;
    border: solid 1px #00d989;
    background-color: #eefdf7;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #00d989;
    margin-left: 10px;
    -webkit-tap-highlight-color: transparent;
`