import styled from "styled-components";
import Countdown, { CountdownRendererFn } from 'react-countdown'
import { FC } from 'react'

interface ITimerProps {
    /** 结束事件秒数 */
    time: number
    /** render */
    render: CountdownRendererFn | undefined
}

export const RebaseTimer: FC<ITimerProps> = ({ time, render }) => {
    return (
        <>
            {time && <Countdown date={time} now={() => Date.now()} renderer={render} />}
        </>
    )
}