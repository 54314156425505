import React, { FC } from 'react'
import styled from 'styled-components'
import { LoadingSkeleton } from 'components'
/**
 * 统计项属性
 */
export interface ICountProps {
    /** 图标 */
    icon: string
    /** 标题 */
    title: string
    /** 值 */
    value: string
    /** value 颜色 */
    color?: string
    /** loading */
    loading: boolean
}

export const CountItem: FC<ICountProps> = ({ icon, title, value, color = "#fff", loading }) => {
    return (
        <Wrapper>
            <Icon src={icon}></Icon>
            <Right>
                <Title>{title}</Title>
                {loading && <LoadingSkeleton />}
                {!loading && <Value color={color}>{value}</Value>}
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
     width: 285px;
     height: 100px;
     padding: 25px 0 26px 30px;
     border-radius: 5px;
     border: solid 1px #3c3a5a;
     background-color: rgba(38,36,60,0.9);
     display: flex;
     align-items: center;
     box-sizing: border-box;
     @media screen and (max-width:960px) {
         width: 100%;
     }
`

const Icon = styled.img`
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin-right: 25px;
`
const Right = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 12px;
    line-height: 1.17;
    color: rgba(255,255,255,0.7);
    margin-bottom: 15px;
`
const Value = styled.div<{ color: string }>`
     font-size: 20px;
     font-weight: bold;
     color:${({ color }) => color};
`