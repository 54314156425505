import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

/**
 * useEffect 确保连接上钱包，并且链正确时才执行
 * @param initFunction 初始化执行函数
 * @param destroyFunction 销毁函数
 */
export function useWeb3Effect(initFunction: () => void,  destroyFunction?: () => void,dependices?:any[]) {
    dependices=dependices||[]
    const { account, library, error, chainId } = useWeb3React()
    useEffect(() => {
        // 当chainId 并且account 存在时才去请求数据
        if (chainId && account && library && !error) {
            initFunction()
        }
        return () => {
            destroyFunction && destroyFunction()
        }
    }, [
        account,
        library,
        error,
        chainId,
        ...dependices
    ])
}