import { createSlice } from '@reduxjs/toolkit'
import { ZERO } from 'config'
import { IP3dState } from './type'

/** 初始化状态 */
const initState: IP3dState = {
    ethBalance: ZERO,
    p3dBalance: ZERO,
    loading: false,
    p3dPrice: ZERO,
    dividends: ZERO,
    ethPrice: ZERO,
    referralRewards: ZERO
}

const Slice = createSlice({
    name: "nft/p3d",
    initialState: initState,
    reducers: {
        /** 初始化状态 */
        init: (state, action) => {
            const { ethBalance, p3dBalance, dividends, p3dPrice, ethPrice, referralRewards } = action.payload
            state.ethBalance = ethBalance
            state.p3dBalance = p3dBalance
            state.dividends = dividends
            state.p3dPrice = p3dPrice
            state.ethPrice = ethPrice
            state.referralRewards = referralRewards

        },
        /** 设置loading */
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

/** reducer */
export const P3dReducer = Slice.reducer
/** actioN */
export const { init, setLoading } = Slice.actions