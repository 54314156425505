import styled from "styled-components";
import { FC, useEffect } from 'react'
import { X } from 'react-feather'
/** 提示弹窗属性 */
export interface IToastProps {
    /** contetn 内容 */
    content: string
    /** 间隔时间秒数自动关闭 */
    interVal?: number
    /** 关闭事件 */
    onClose: () => void
    /** borderColor */
    borderColor?: string
}

export const Toast: FC<IToastProps> = ({ content, onClose, interVal = 5000, borderColor = "#8dff1e" }) => {
    let timerId: any = null
    useEffect(() => {
        timerId = setTimeout(() => {
            onClose()
        }, interVal)
        return () => timerId && clearTimeout(timerId)
    }, [])
    const handleClose = () => {
        if (timerId) {
            clearTimeout(timerId)
        }
        onClose()
    }
    return (
        <Wrapper color={borderColor}>
            <Message color={borderColor}>{content}</Message>
            <Button onClick={handleClose} color={borderColor}>
                <X></X>
            </Button>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ color: string }>`
    padding: 13px 15px;
    border-radius: 3px;
    border: solid 1px ${({ color }) => color};
    background-color: #000;
    position: fixed;
    top:80px;
    right:104px;
    min-width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index:50000;
`
const Message = styled.div<{ color: string }>`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color:${({ color }) => color};
`
const Button = styled.div<{ color: string }>`
    border:none;
    outline: none;
    background:transparent;
    cursor:pointer;
    color:${({ color }) => color};
    font-size:12px;
    user-select: none;
`