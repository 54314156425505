import { DepositDrawType, ONE, RATIO, ZERO } from 'config'
import { ethers, BigNumber } from 'ethers'

/** 
 * 转换number 类型到16进制
 * 比如56 兑换成16进制为38
 */
export const ConvertNumToHex = (num: number): string => {
    return `0x${num.toString(16)}`
}

/** 16 进制转出数字 */
export const ConvertHexToNumber = (hex: string): number => {
    // 不存在返回0
    if (!hex) {
        return 0
    }
    // 去掉0x 十六进制前缀
    hex = hex.replace("Ox", "")
    // 转出数字
    return parseInt(hex, 16)
}

/**
 * 获取数字的小数点位数
 * @param num 数字
 */
export const getDecimals = (num: number) => {
    const str = num.toString()
    if (!str || str == "") {
        return 0
    }
    const index = str.lastIndexOf(".")
    if (index == -1) {
        return 0
    }
    return str.length - index - 1
}

/**
 * 裁剪用户钱包账号，中间使用... 代替
 * @param str 钱包地址
 * @returns 
 */
export function shorten(str: string) {
    if(!str) return ""
    if (str.length < 10) return str;
    return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

/**
 * 钱包代币数量转出字符串
 * @param amount 代币数量
 * @param format 保留几位小数，默认6位
 * @param decimals 代币小数点位数
 * @returns 格式化后的字符串
 */
export function formatTokenNum(amount: BigNumber | undefined, format = 6, decimals = 18) {
    if (amount === undefined) {
        return ''
    }
    const str = ethers.utils.formatUnits(amount, decimals)
    return str.substring(0, str.lastIndexOf('.') + format + 1)
}

/**
 * 格式化价格千位符加,
 * @param price  价格
 * @param format 保留小数点为数，默认值为2
 * @param decimals 代币的小数点位数
 *  @returns 返回带有千位符的字符串
 */
export function formatTokenPrice(price: BigNumber | undefined, format = 2, decimals = 18): string {
    if (price == undefined) {
        return ""
    }
    // 转成ethers 
    let str = ethers.utils.formatUnits(price, decimals)
    // 保留的小数点位数
    str = str.substring(0, str.lastIndexOf('.') + format + 1)
    // 转成浮点数
    const value = parseFloat(str)
    // 格式化千位符加,
    const rtn = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: decimals,
        minimumFractionDigits: 1,
    }).format(value)
    return rtn
}

/** 计算 */
export function formatBigNumber(value: BigNumber | undefined, decimals = 2) {
    if (value === undefined) {
        return 0
    }
    const res = ethers.utils.formatEther(value)
    return Number(res).toFixed(decimals)
}