import {TW,TW_HOVER_IMG,TE_IMG,TE_HOVER_IMG,DOC_IMG,DOC_HOVER_IMG} from 'assets'
/**
 * links 链接
 */
export const  LINKS =[
    {
        id:1,
        icon:TW,
        link:"https://x.com/NotJoker_com",
        hoverImg:TW_HOVER_IMG
    },
    {
        id:2,
        icon:TE_IMG,
        link:"https://t.me/notjokercom",
        hoverImg:TE_HOVER_IMG
    },
    {
        id:3,
        icon:TE_IMG,
        link:"https://t.me/notjoker_com",
        hoverImg:TE_HOVER_IMG
    }
]
