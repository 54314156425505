import { GAS_MULTIPLE, P3D_DECIMALS, ZERO } from 'config'
import { BigNumber, ethers, providers } from 'ethers'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state'
import { EthPriceContract, P3dContract } from 'utils'
import { init, setLoading } from './slice'

/** p3d 状态 */
export function useP3dState() {
    return useSelector((state: AppState) => state.p3d)
}

/** eth 转p3d */
export function useEthToP3d(provider: providers.Web3Provider) {
    return async (amount: BigNumber) => {
        try {
            // p3d 合约对象
            const contract = P3dContract(provider)
            const res = await contract.calculateTokensReceived(amount)
            return res
        } catch (ex) {
            console.log("useEthToP3d error:", ex)
            return ZERO
        }
    }
}

/** p3d 转eth */
export function useP3dToEth(provider: providers.Web3Provider) {
    return async (amount: BigNumber) => {
        try {
            // p3d 合约对象
            const contract = P3dContract(provider)
            const res = await contract.calculateEthereumReceived(amount)
            return res
        } catch (ex) {
            console.log("useEthToP3d error:", ex)
            return ZERO
        }
    }
}

/** 初始化p3d */
export function useInitP3d(provider: providers.Web3Provider) {
    const dispatch = useDispatch()
    const onConvertP3dToEth = useP3dToEth(provider)
    /** 
     * 初始化p3d 状态
     * @param account {string} 用户钱包地址
     */
    return async (account: string) => {
        // 设置加载loading
        dispatch(setLoading(true))
        try {
            let arr = []
            // 获取用户eth的数量 
            arr.push(provider.getBalance(account))
            // p3d 合约对象
            const contract = P3dContract(provider)
            // 获取用户p3d 数量 
            arr.push(contract.balanceOf(account))
            // 分红
            //arr.push(contract.myDividends(false))
            arr.push(contract.dividendsOf(account))
            // 邀约奖励
            arr.push(contract.referralBalance(account))
            // eth 价格合约对象
            const ethPriceContract = EthPriceContract()
            arr.push(ethPriceContract.latestRoundData())
            const [ethBalance, p3dBalance, dividends,referralRewards, data] = await Promise.all(arr)
            const { answer } = data;
            console.log("dividends",dividends)
            // p3d 值多少eth
            let p3dPrice = ZERO
            if (!(p3dBalance.eq(ZERO))) {
                p3dPrice = await onConvertP3dToEth(p3dBalance)
            }

            dispatch(init({
                ethBalance,
                p3dBalance,
                dividends,
                p3dPrice,
                ethPrice: answer,
                referralRewards
            }))
            // loading 结束
            dispatch(setLoading(false))

        } catch (ex) {
            console.log("useInitP3d error:", ex)
            dispatch(setLoading(false))
        }
    }
}

/** 买入- 用eth 购买p3d */
export function useBuyP3d(provider: providers.Web3Provider) {
    const onInit = useInitP3d(provider)
    /**
     * 用eth 购买p3d
     * @param account {string} 用户钱包地址
     * @param value {string} eth 输入的数量
     * @param referral 邀约人的钱包地址，默认为零地址
     * @returns boolean true 成功，false 失败
     */
    return async (account: string, value: string, referral: string) => {
        try {
            // 将输入的eth 数量转出大数
            const bigValue = ethers.utils.parseEther(value)
            // p3d 合约对象
            const contract = P3dContract(provider.getSigner())
            // eth是链上基础代币，数量需要通过value 的方式传递
            const gas = await contract.estimateGas.buy(referral, { value: bigValue });
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.buy(referral, { gasLimit, value: bigValue });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useBuyP3d error:", ex)
            return false
        }
    }
}

/** 卖出p3d 获取eth */
export function useSellP3d(provider: providers.Web3Provider) {
    const onInit = useInitP3d(provider)
    return async (account: string, value: string) => {
        try {
            // 将输入的eth 数量转出大数
            const bigValue = ethers.utils.parseUnits(value, P3D_DECIMALS)
            // p3d 合约对象
            const contract = P3dContract(provider.getSigner())
            // 预计使用gas 数量
            const gas = await contract.estimateGas.sell(bigValue);
            // 提升gas 
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.sell(bigValue, { gasLimit });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useSellP3d error:", ex)
            return false
        }
    }
}

/** 领取奖金 */
export function useWithdraw(provider: providers.Web3Provider) {
    const onInit = useInitP3d(provider)
    return async (account: string) => {
        try {
            // p3d 合约对象
            const contract = P3dContract(provider.getSigner())
            // 预计使用gas 数量
            const gas = await contract.estimateGas.withdraw();
            // 提升gas 
            const gasLimit = gas.mul(GAS_MULTIPLE).div(10)
            /** 交易对象 */
            const tx = await contract.withdraw({ gasLimit });
            await tx.wait()
            // 交易完成以后，所有数据发生变化，重新获取新的数据
            onInit(account)
            return true
        } catch (ex) {
            console.log("useSellP3d error:", ex)
            return false
        }
    }
}