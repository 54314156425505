import { AddEthereumChainParams } from '../config'

export enum MetaMaskRequestMethods {
    /**
     * 添加一条现在metamask 中不存在的链
     */
    addEthereumChain = "wallet_addEthereumChain",
    /**
     * 添加token 代币到钱包中
     */
    watchAsset = 'wallet_watchAsset',
    /**
     *  获取当前连接网络的链的id
     */
    chainId = 'eth_chainId',
    /**
     * eth_requestAccounts 获取账号(或者可以理解为链接metamask 钱包)
     */
    requestAccounts = 'eth_requestAccounts',
    /**
     * 获取账户地址
     */
    accounts = 'eth_accounts',
    /**
     * 获取最新区块编号
     */
    blockNumber = 'eth_blockNumber'
}
type EthereumProviderEip1193 = {
    request: (args: {
        method: string
        params?: unknown[] | object
    }) => Promise<unknown>
}

type EthereumProviderSend = {
    send: (method: string, params: string[]) => Promise<unknown>
}

type EthereumProviderSendAsync = {
    sendAsync: (
        params: {
            method: string
            params: string[]
            from: string
            jsonrpc: '2.0'
            id: number
        },
        callback: (err: Error, result: unknown) => void
    ) => void
    selectedAddress: string
}

/**
 * window.ethereum 类型
 * Eip-1193 规范
 */
export type EthereumProvider = EthereumProviderEip1193 &
    EthereumProviderSend &
    EthereumProviderSendAsync

function isUnwrappedRpcResult(response: unknown): response is {
    error?: string
    result?: unknown
} {
    return (
        typeof response === 'object' && response !== null && 'jsonrpc' in response
    )
}
/**
 * 防止有的客户端没有包裹response
 */
export function rpcResult(response: unknown): unknown | null {
    // Some providers don’t wrap the response
    if (isUnwrappedRpcResult(response)) {
        if (response.error) {
            throw new Error(response.error)
        }
        return response.result || null
    }

    return response || null
}
/**
 * metamask request 方法封装
 * @param ethereum provider, 浏览器中使用window.ethereum
 * @param method 请求方法
 * @param params 参数
 * @returns 
 */
export async function ethereumRequest(
    ethereum: EthereumProvider,
    method: string,
    params: any
): Promise<any> {
    console.log("method",method)
    console.log("params",params)
    // If ethereum.request() exists, the provider is probably EIP-1193 compliant.
    if (ethereum.request) {
        return ethereum.request({ method, params }).then(rpcResult)
    }
    // This is specific to some older versions of MetaMask combined with Web3.js.
    if (ethereum.sendAsync && ethereum.selectedAddress) {
        return new Promise((resolve, reject) => {
            ethereum.sendAsync(
                {
                    method,
                    params,
                    from: ethereum.selectedAddress,
                    jsonrpc: '2.0',
                    id: 0,
                },
                (err: Error, result: any) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                }
            )
        }).then(rpcResult)
    }
    // If none of the previous two exist, we assume the provider is pre EIP-1193,
    // using .send() rather than .request().
    if (ethereum.send) {
        return ethereum.send(method, params).then(rpcResult)
    }
    throw new Error(
        'The Ethereum provider doesn’t seem to provide a request method.'
    )
}

/**
 * 获取区块编码
 * @param ethereum provider, 默认使用window.ethereum
 * @returns 
 */
export async function getBlockNumber(ethereum?: EthereumProvider) {
    ethereum = ethereum ?? window?.ethereum as any
    return ethereumRequest(ethereum!, MetaMaskRequestMethods.blockNumber, [])
}
/**
 * 添加链到metamask 上
 */
export async function addChainToBlock(id: number, ethereum?: EthereumProvider) {
    ethereum = ethereum ?? window?.ethereum as any
    const params = AddEthereumChainParams[id]
    // ! 确保ethereum 部位null
    return ethereumRequest(ethereum!, MetaMaskRequestMethods.addEthereumChain, [params])
}


/**
 * 添加代币到链上
 * @param tokenAddress 代币合约地址
 * @param tokenSymbol 代币图标
 * @param tokenDecimals 代币小数点位数
 * @param image 代币图标
 * @param ethereum provider
 * @returns 
 */
export async function addTokenToBlock(tokenAddress: string, tokenSymbol: string, tokenDecimals: number, image: string, ethereum?: EthereumProvider) {
    ethereum = ethereum ?? window?.ethereum as any
    const params: any = {
        type: 'ERC20',
        options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image
        }
    };
    // ! 确保ethereum 部位null
    return ethereumRequest(ethereum!, MetaMaskRequestMethods.watchAsset, params)
}