import React, { ChangeEvent, FC } from 'react'
import styled from 'styled-components'

/** borrow input 属性 */
export interface IBorrowInput {
    /** 值 */
    value: string
    /** input onChange 事件 */
    onChange: (value: string) => void
    /** placeholder */
    placeholder: string
    /** icon 代币图标 */
    icon: string
    /** balance */
    balance: string
    /** 市值 */
    market: string
}

/** BorrowInput */
export const BorrowInput: FC<IBorrowInput> = ({ value, onChange, placeholder, icon, balance, market }) => {
    /** input onChange 事件 */
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(value)
    }
    return (
        <Wrapper>
            <Left>
                <Icon src={icon}></Icon>
                <InputWrapper>
                    <Input value={value} onChange={handleChange} placeholder={placeholder} />
                    <Market>~$ {market}</Market>
                </InputWrapper>
            </Left>
            <Balance>
                Balance: {balance}
            </Balance>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 18px 20px 17px;
    border-radius: 5px;
    border: solid 1px #20d58f;
    background-color: #eefdf7;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Left = styled.div`
    display: flex;
    align-items: center;
`

const Balance = styled.div`
    font-size: 12px;
    color: #aaa;
`
const Icon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 15px;
`

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const Input = styled.input`
    font-size: 18px;
    font-weight: bold;
    color: #222;
    outline: none;
    border: none;
    width:70%;
    background: transparent;
    &::placeholder{
        color: #aaa;
    }
`
const Market = styled.div`
    color: #989898;
    font-size:12px;
`