import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from './provider'
import { GlobalStyle } from 'styled'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider>
      {/* 全局样式 */}
      <GlobalStyle/>
      <App />
    </Provider>

  </React.StrictMode>
);
