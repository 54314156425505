
import React, { useMemo } from 'react'
import styled from 'styled-components'

/**
 * page item 属性
 */
export interface PageItemProps {
    // 是否激活
    active?: boolean,
    // 是否可用
    disabled?: boolean,
    // 名称
    name: string,
    onPageClick?(page: string, e: React.MouseEvent<HTMLAnchorElement>): void
}
export interface PaginationProps {
    // 当前页数
    page: number,
    // 每页条数
    size: number,
    // 总条数
    total: number,
    onPageChange?(page: number): void
}


const PageItem: React.FC<PageItemProps> = ({ active, disabled, name, onPageClick }: PageItemProps) => {

    const Component = active || disabled ? ItemSpan : ItemAnchor;
    const handleClick = (size: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        onPageClick && onPageClick(size, e);
    }
    return (
        <ItemContainer>
            {
                (active || disabled) ? <ItemSpan active={active}>{name}</ItemSpan> : <ItemAnchor onClick={(e) => handleClick(name, e)}>{name}</ItemAnchor>
            }
        </ItemContainer>

    )
}
// 获取总页数
function getTotalPage(size: number, total: number) {
    let page: number = 0;
    if (total % size == 0) {
        page = parseInt((total / size).toString())
    } else {
        page = parseInt((total / size).toString()) + 1
    }
    return page
}
function getPaging(totalPage: number, page: number) {
    const arr: PageItemProps[] = [];
    if (totalPage <= 0 || page < 1) {
        return arr
    }
    let firstDisabled = false;
    let lastDisabled = false;
    let isLoadEllipsis = false;
    // 默认显示5个分页
    const showItemCount = 5;
    let start = page - 2 < 0 ? 1 : page - 2;
    start = start === 0 ? 1 : start;
    let end = start + showItemCount;
    end = end > totalPage ? (totalPage + 1) : end;
    // 如果总页数小于5 ，则显示全部
    // 保证始终有5个分页，不够5页，则显示全部
    start = end - showItemCount <=0 ? 1 : end - showItemCount
   
    if (page == 1) {
        firstDisabled = true
    } else {
        firstDisabled = false
    }
    if (end > showItemCount && (end-1) < totalPage) {
        isLoadEllipsis = true
    } else {
        isLoadEllipsis = false
    }
    let endDisabled = true
    if (page == end - 1) {
        endDisabled = true
    } else {
        endDisabled = false
    }
    // 加载前一页
    arr.push({ name: '<', disabled: firstDisabled, })
    // 加载第一页和...
    if(start>2){
        arr.push({ name: '1', active:false })
        arr.push({ name: '...', disabled: true })
    }
    for (let i = start; i < end; i++) {
        let active = i === page ? true : false
        arr.push({ name: i.toString(), active })
    }
    // 加载...
    if (isLoadEllipsis) {
        arr.push({ name: '...', disabled: true })
        arr.push({ name: (totalPage).toString(), active:false })
    }
    // 加载下一页
    arr.push({ name: '>', disabled: endDisabled })
    return arr
}
const Pagination: React.FC<PaginationProps> = ({ page = 1, size = 10, total = 0, onPageChange }) => {
    const totalPage = useMemo(() => {
        return getTotalPage(size, total)
    }, [size, total])
    const PageList = useMemo(() => {
        const arr = getPaging(totalPage, page)
        return arr
    }, [page, totalPage])
    const handlePageChange = (name: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        let currentPage = page
        switch (name) {
            // 前一页
            case '<':
                currentPage = page - 1;
                break;
            case '>':
                currentPage = page + 1;
                break;
            default:
                currentPage = parseInt(name)
                break;
        }
        onPageChange && onPageChange(currentPage)
    }
    return (
        <PaginationContainer>
            {
                PageList && PageList.map((item: PageItemProps, index: number) => {
                    return (
                        <PageItem {...item} key={`pagination-${index}`} onPageClick={handlePageChange} />
                    )
                })
            }
        </PaginationContainer>
    )
}
const PaginationContainer = styled.ul`
    display:flex;
    list-style:none;
    margin:0;
    padding:0;
`
const ItemContainer = styled.li`
   margin-top:3px;
   & span,& a{
       display:inline-block;
       width: 24px;
       line-height: 24px;
       height: 24px;
       text-align:center;
       margin: 0 5px 0 0;
   }
`

const ItemAnchor = styled.a`
    border: solid 1px #ebebeb;
    color:#999;
    cursor: pointer;
    font-size:12px;
`
const ItemSpan = styled.span<{ active?: boolean }>`
    font-size:12px;
    border: solid 1px ${props => props.active ? '#00d989' : `#ebebeb`};
    background-color: ${props => props.active ? '#00d989' : `#fff`};
    color:#${props => props.active ? 'fff' : '999'};
`

export default Pagination