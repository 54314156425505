import React, { ChangeEvent, FC, useMemo } from "react";
import styled from "styled-components";

/** 滑点列表 */
let Slippagelist = [
    {
        id: 1,
        value: '1',
        isActive: true
    },
    {
        id: 2,
        value: '3',
        isActive: false
    },
    {
        id: 3,
        value: '5',
        isActive: false
    },
    {
        id: 4,
        value: '0',
        isActive: false
    }
]

/** 滑点tab 组件属性 */
export interface ISlippageProps {
    /** current 当前滑点值 */
    current: string
    /** 点击切换输入change 事件 */
    onChange: (value: string) => void
}

/** 滑点tab 组件 */
export const SlippageTab: FC<ISlippageProps> = ({ current, onChange }) => {
    /** 处理list */
    const List = useMemo(() => {
        return Slippagelist.map(item => {
            if (item.value == current) {
                item.isActive = true
            } else {
                item.isActive = false
            }
            return { ...item }
        })
    }, [current,Slippagelist])
    // 输入框change 事件
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(value)
        Slippagelist=Slippagelist.map(item=>{
            if(item.id==4){
                item.value=value
            }
            return {...item}
        })
    }
    // 标签点击事件
    const handleClick = (value: string) => {
        onChange(value)
    }
    return (
        <Wrapper>
            {
                List.map(item => {
                    return (
                        <React.Fragment key={`slippage_item_${item.id}`}>
                            {item.id != 4 && <Item className={item.isActive?"active":""} onClick={e=>handleClick(item.value)}>{item.value}%</Item>}
                            {item.id == 4 && <ItemInput className={item.isActive?"active":""} onClick={e=>handleClick(item.value)}>
                                <Input value={item.value} onChange={handleInputChange}></Input>
                                <Text>%</Text>
                            </ItemInput>}
                        </React.Fragment>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
const Item = styled.div`
    cursor: pointer;
    -webkit-tap-highlight-color:transparent;
    width: 50px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px #3f3f55;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size: 12px;
    box-sizing: border-box;
    color: #a1a3c4;
    &.active{
        color: #ffc100;
        border: solid 1px #ffc100;
    }
`
const ItemInput = styled.div`
cursor: pointer;
-webkit-tap-highlight-color:transparent;
    
    border-radius: 15px;
    background-color: #101124;
    height: 30px;
    padding:0 10px;
    display:flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    &.active{
        border: solid 1px #ffc100;
    }
`
const Input = styled.input`
    color:#fff;
    font-size: 12px;
    border:none;
    flex:1;
    outline: none;
    background: transparent;
    width: 85px;
`
const Text = styled.div`
   color: #a1a3c4;
   font-size: 12px;
`
