import { useEffect } from 'react'
import { SUPPORT_CHAINS,INIT_CHAIID,Injected } from 'config'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {addChainToBlock} from 'utils'

/**
 * 用户钱包链接
 * @param chainId 链id,默认初始化为process.env.REACT_APP_CHAINID
 */
export const useAuth = (chainId:number=INIT_CHAIID)=>{
    /** web3 react activate- 链接钱包，deactivate - 断开链接 */
    const {activate,deactivate} = useWeb3React()

    /** 链接钱包 */
    const Login = ()=>{
        /** isAuthorized 判断是否支持window.ethereum 全局变量 */
        Injected.isAuthorized().then(res=>{
            // 链接钱包
            activate(Injected,(error:Error)=>{
                // 当链不存在钱包或者链错误时
                if(error instanceof UnsupportedChainIdError){ // 不是连接器设置的supportIds 时，即不支持的链时
                    // 添加链，成功添加链以后链接钱包
                    addChainToBlock(chainId).then(res=>{
                        activate(Injected)
                    })
                }
            })
        })
    }

    /** 退出钱包链接 */
    const LoginOut = ()=>{
        deactivate()
    }

    return [Login,LoginOut]
}