import { ethers } from "ethers"

/** 借款页面tab 枚举 */
export enum BorrowTabType {
    /** 借款 */
    Borrow,
    /** 我的仓位 */
    MyPosition
}

/** 借款页面tab list */
export const BorrowTabList = [
    {
        id: 1,
        name: "Borrow",
        active: false,
        type: BorrowTabType.Borrow
    },
    {
        id: 2,
        name: "My Position",
        active: false,
        type: BorrowTabType.MyPosition
    }
]

/** 仓位tab  */
export enum PositionabType {
    /** 仓位 */
    Position,
    /** 清算余额 */
    Liqudation
}
/** 仓位tab list */
export const PositionTabList = [
    {
        id: 1,
        name: "Position",
        active: false,
        type: PositionabType.Position
    },
    {
        id: 1,
        name: "Liqudation",
        active: false,
        type: PositionabType.Liqudation
    }
]

/**开仓tab 类型枚举*/
export enum TroveTabType {
    Normal,
    Leverage,
    Deleverage
}
/** 开仓tab list */
export const TroveTabList = [{
    id: 1,
    name: "Normal",
    active: false,
    type: TroveTabType.Normal,
    width: 70
},
{
    id: 2,
    name: "Leverage",
    active: false,
    type: TroveTabType.Leverage,
    width: 70
},
{
    id: 1,
    name: "Deleverage",
    active: false,
    type: TroveTabType.Deleverage,
    width: 80
}
]

/** 借款页面存取 枚举 */
export enum DepositDrawType {
    /** 借款 */
    Deposit = 1,
    /** 我的仓位 */
    Withdraw = 2
}

/** 借款页面tab list */
export const DepositDrawTabList = [
    {
        id: 1, // id 编号，用于key
        name: "Deposit", // tab item 名称
        active: false,// 是否选中状态
        type: DepositDrawType.Deposit,// 类型
        disabled: false// 是否禁用
    },
    {
        id: 2,
        name: "Withdraw",
        active: false,
        type: DepositDrawType.Withdraw,
        disabled: false
    }
]

/** 最少借款YUSD 的数量 */
export const MIN_BORROW_AMOUT = 2000
/** 最少借款YUSD 的数量 大数bigNumber */
export const MIN_BORROW_AMOUT_BG = ethers.utils.parseUnits('2000', 18)
/** 开仓费用 */
export const FIRST_OPEN_FEE_BG = ethers.utils.parseUnits('200', 18)

/** 获取hint address 时常量 */
export const K = 15

/** Max FEE */
export const MAX_FEE = ethers.utils.parseEther('1')