import { FC } from "react";
import styled from "styled-components";

/** lp 代币两种代表图标logo */
export interface ILpTokenIcon {
    /** 代币0 的图标 */
    icon0: string
    /** 代币1 的图标 */
    icon1: string,
    /** 宽度 */
    width?: number
}

/** lp 两种代币图标 */
export const LPTokenLogo: FC<ILpTokenIcon> = ({ icon0, icon1, width=24 }) => {
    return (
        <Wrapper>
            <Image1 src={icon0} width={width}/>
            <Image2 src={icon1} width={width}/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const Image1 = styled.img<{ width: number }>`
    width: ${({ width }) => width}px;
    height: ${({ width }) => width}px;
    border-radius: 50%;
`
const Image2 = styled(Image1)`
    position: relative;
    margin-left: -15px;
`