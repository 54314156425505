import styled from "styled-components";
import { FC } from 'react'

/** risk 图表类型 */
export enum RiskType {
    green,
    red
}
/** 风险图表 */
export interface IRisk {
    /** 类型 */
    type: RiskType
}

/** 风险图表 */
export const Risk: FC<IRisk> = ({ type }) => {
    const getColor = () => {
        if (type == RiskType.green) {
            return "#30a27a"
        }
        return "#c34556"
    }
    return (
        <Wrapper>
            <Inner color={getColor()}></Inner>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 40px;
    height: 14px;
    border-radius: 3px;
    padding: 3px 4px 3px 4px;
    box-sizing: border-box;
    border: solid 1px rgba(255,255,255,0.2);
`
const Inner = styled.div<{ color: string }>`
    width: 30px;
    height: 6px;
    border-radius: 1px;
    background-color: ${({ color }) => color};
`