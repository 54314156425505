/**
 * 合约地址
 */
export default {
    /**tvl 合约地址 */
    tvl: "0x9490C7f68c8409eD0099610Be3d40487856df59d",
    /** bank 合约地址 */
    bank: "0xda798b1De457f84D1F262ab3Ce1530D71F08ee8D",
    /**开仓合约地址 */
    kaicang: '0x7A713d9Cf745922bA322A8ebd0eaD455156274b0',
    /** leverage 杠杆开仓辅助合约地址 */
    leverageHelper: "0xBECd85758D72Cae2bAA97f084Ef61F04cC7c6dFe",
    /** FairLaunch 合约地址,lend 功能中stake,unstake,claim  */
    fairLaunch:"0x4157634F06A06151c563344332AFF29c46471F99",
    /** farm Pancke*/

    /**
     * multicall
     */
    multicall: "0x41263cba59eb80dc200f3e2544eda4ed6a90e76c",
    /** pancake router 合约地址 */
    pancakeRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    /** biswap router */
    biswapRouter: "0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8",


}