import React, { FC } from 'react'
import styled from 'styled-components'

/** lp token image */
export interface ITokenIcon {
    /** token0 image */
    icon: string
    /** 图标宽度 */
    width: number
}

/** 单个代币的图标 */
export const TokenImage: FC<ITokenIcon> = ({ icon, width }) => {
    return (
        <Image width={width} src={icon} />
    )
}

const Image = styled.img<{ width: number }>`
    width:${({ width }) => width}px;
    height:${({ width }) => width}px;
    border-radius: 50%;
    position: relative;
`