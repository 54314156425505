import styled from 'styled-components'
/** 表格头部 */
export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    width:100%;
    height:44px;
    @media screen and (max-width:960px){
        display: none;
    }
`
/** 表格列 */
export const HeaderColumn = styled.div`
    flex:1;
    color: #6a6b87;
    font-size:12px;
    display: flex;
`