import { createSlice } from '@reduxjs/toolkit'
import { ZERO_ADDRESS } from 'config'
import { IGlobalState } from './type'

/** 初始状态 */
const initState: IGlobalState = {
    Referral: ZERO_ADDRESS
}

const Slice = createSlice({
    name: "nft/global",
    initialState: initState,
    reducers: {
        /** 初始化state */
        initGlogalState(state, action) {
            state.Referral = action.payload
        }
    }
})

/** 导出reducer */
export const GlobalReducer = Slice.reducer

/** 导出action */
export const { initGlogalState } = Slice.actions