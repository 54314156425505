import styled from "styled-components";
import { FC } from 'react'

/** 代币价格属性 */
export interface ITokenPriceProps {
    /** icon 代币图标 */
    icon: string
    /** 代币价格 */
    price: string
    /** 购买事件 */
    onBuy: () => void
}

/** 代币价格 */
export const TokenPrice: FC<ITokenPriceProps> = ({ icon, price, onBuy }) => {
    return (
        <Wrapper>
            <Left>
                <Icon src={icon}></Icon>
                <Text>{price}</Text>
            </Left>
            <BuyButton onClick={onBuy}>
                Buy
            </BuyButton>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0px 10px 0px 12px;
    border-radius: 20px;
  border: solid 1px #44455a;

`
const Left = styled.div`
    display: flex;
    align-items: center;
`
const Icon = styled.img`
    width:20px;
    height:20px;
    margin-right: 10px;
`
const Text = styled.div`
    color: #a1a3c4;
    font-size: 14px;
`
const BuyButton = styled.div`
    cursor: pointer;
    user-select:none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px #ffc100;
    color: #ffc100;
    letter-spacing: 0.2px;
    font-size: 12px;
    -webkit-tap-highlight-color:transparent;
`