import { useState, FC } from 'react'
import styled from 'styled-components'

export interface ICheckBoxProps {
    /** 用于htmlfor */
    id: string
    /** value */
    value: boolean
    /** onChange 事件 */
    onChange: () => void
    /**label 后面跟的值 */
    lableText?: string
}
/**
 * checkbox 组件
 */
export const CheckBox: FC<ICheckBoxProps> = ({ id, value, onChange, lableText }) => {
    return (
        <Wrapper>
            <Check id={id} checked={value} onChange={onChange}></Check>
            <CheckLable htmlFor={id}>{lableText}</CheckLable>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    position:relative;
`
const CheckLable = styled.label`
    font-size: 12px;
    font-weight: bold;
    color:#fff;
    margin-left:10px;
    cursor: pointer;
    &::before{
        content: '';
        border-radius: 2px;
        border: solid 1px #ffb03b;
        background-color: #fff;
        width:16px;
        height: 16px;
        position:absolute;
        z-index: 5;
        cursor:pointer;
        left:0;
        box-sizing: border-box;
        top:0;
    }
    &::after{
        content: "\\2713";
        display:inline-block;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        color:#ffb03b;
        font-size:16px;
        font-weight: bold;
        position:absolute;
        z-index: 6;
        left:2px;
        top:-4px;
        box-sizing: border-box;
       
    }
`
const Check = styled.input.attrs(props =>({
        type: "checkbox"
    
}))`
     &:checked + ${CheckLable}::after{
        transform: scaleX(1);
    } 
`