import { configureStore } from '@reduxjs/toolkit'
import { GlobalReducer } from './global'
import { P3dReducer } from './p3d'
import {JokerReducer} from './joker'

export const store = configureStore({
    reducer: {
        global: GlobalReducer,
        p3d: P3dReducer,
        joker:JokerReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch