import styled from "styled-components";

/** 提示 */
export const Tip=styled.div`
    width:100%;
    box-sizing: border-box;
    padding: 15px 51px 15px 52px;
    border-radius: 5px;
    border: solid 1px #f98d6b;
    background-color: #fff1ec;
    color: #f75725;
    font-size: 14px;
    line-height: 1.43;
`