
/**
 * 链数据信息
 */
export interface Chain {
    /**
     * 链的名称
     */
    name: string
    /** 链id */
    chainId: number
    /** 平台代币 */
    nativeCurrency: {
        /** 代币的名称 */
        name: string // e.g. 'Goerli ETH',
        /** 代币符号 */
        symbol: string // e.g. 'gorETH',
        /** 代币小数点 */
        decimals: number // e.g. 18,
    }
    /** rpc url */
    rpcUrl: string
    /** 区块浏览器 */
    blockScan: string
}

/** 链集合信息 */
export const CHAINS: { [key: number]: Chain } = {
    43113: {
        name: "AVAX",
        chainId: 43113,
        nativeCurrency: {
            name: "Avalanche",
            symbol: "AVAX",
            decimals: 18,
        },
        rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
        blockScan: "https://testnet.snowtrace.io/"
    }
}

/**
 * 添加一条链到metamask 上时，请求的网络参数
 */
 export const AddEthereumChainParams: { [key: number]: any } = {
    /* 43113: {
        chainId: "0xa869",
        chainName: "AVAX",
        nativeCurrency: {
            name: "Avalanche",
            symbol: "AVAX",
            decimals: 18,
        },
        rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://testnet.snowtrace.io/"]
    },
    56: {
        chainId: `0x38`,
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'bnb',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed1.ninicoin.io', 'https://bsc-dataseed1.defibit.io', 'https://bsc-dataseed.binance.org'],
        blockExplorerUrls: [`https://bscscan.com/`],
    }, */
    4: {
        chainId: `0x4`,
        chainName: 'Rinkeby test',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: [`https://rinkeby.etherscan.io/`],
    },
}

/** 当前应用支持的链 */
export const SUPPORT_CHAINS: number[] = Object.keys(AddEthereumChainParams).map(item => parseInt(item))

/** 初始化链,默认链接的链 */
export const INIT_CHAIID = parseInt(process.env.REACT_APP_CHAINID || '4')

/** 支持的链列表  */
export const CHAIN_ICON_LIST =[
    {
        id:1,
        icon:'BNB',
        name:"BSC"
    }
]