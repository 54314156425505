import AVALANCHETEST from './avalanchetest'
import RINKEBY from './rinkeby'
const ADDRESS: { [key: number]: any } = {
    /** avalanche Fuji Testnet */
    56: AVALANCHETEST,
    /** rinkeby test 网络 */
    4:RINKEBY
}

// 当前链id
const currentChainId = parseInt(process.env.REACT_APP_CHAINID || '56')
/** 合约地址 */
export const CONTRACT_ADDRESS = ADDRESS[currentChainId]