import { FC, useEffect, useState } from 'react'
import styled from "styled-components";

/** 单个选项 */
export interface ItabItem {
    /** id 编码用于key */
    id: number
    /** tab item 名称 */
    name: string
    /** 类型 */
    type: any
    /** 是否已选中 */
    active: boolean
    /** 是否禁用 */
    disabled?: boolean
}
/** tab bottom 属性 */
export interface ITabBottomProps {
    /** tab 集合 */
    list: ItabItem[]
    /** 当前选中的type */
    currentType: any
    /** tab 切换 */
    onTabChange: (type: any) => void
    /** item width */
    itemWidth?: number
    /** font-size */
    fontSize?: number
    /** key */
    lkey?: string
    /** font-weight */
    fontWeight?: string
}

/** 底部效果的tab */
export const TabBottom: FC<ITabBottomProps> = ({ lkey = "", list, currentType, fontWeight = "bold", onTabChange, itemWidth = 80, fontSize = 14 }) => {
    const [index, setIndex] = useState(0)
    useEffect(() => {
        const index = list.findIndex(item => item.type === currentType)
        if (index != -1) {
            setIndex(index)
        }
    }, [currentType])
    /**
     * 切换标签
     */
    const handleChange = (item: ItabItem) => {
        if(item.disabled){
            return
        }
        onTabChange && onTabChange(item.type)
    }
    return (
        <Wrapper index={index}>
            {
                list.map(item => {
                    return (
                        <Item index={index} fontSize={fontSize} width={itemWidth} fontWeight={fontWeight} onClick={e => handleChange(item)} key={`item_tab_bottom_${item.id}_${lkey}`}>{item.name}</Item>
                    )
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled.nav<{ index: number }>`
    list-style: none;
    display: flex;
    border-bottom:  solid 1px #404066;
    position:relative;
    &::after{
        content: '';
        position: absolute;
        width: 50%;
        left:${({ index }) => index * 50}%;
        bottom:-1px;
        height: 1px;
        background-color:#e9ad0e ;
        transition: all 0.3s linear;
    }
    @media screen and (max-width:960px) {
        width:100%;
    }
`
const Item = styled.li<{ width: number, fontSize: number, fontWeight: string, index: number }>`
    cursor: pointer;
    width: ${({ width }) => width}px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ fontSize }) => fontSize}px;
    color: #a1a3c4;
    padding-bottom: 10px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &:nth-child(${({ index }) => index + 1}){
        color: #e9ad0e;
    }
    @media screen and (max-width:960px) {
        width:50%;
    }
`