import React, { FC } from 'react'
import styled from 'styled-components'
import { LoadingSkeleton } from 'components'
/** 代币价格展示属性  */
export interface ITokenPriceLoadingProps {
    /**代币图标 */
    icon: string
    /**代币价格 */
    price: string
    /** loading */
    loading: boolean
    /** 点击事件 */
    onClick: () => void
}

/** 代币价格展示  */
export const TokenPice: FC<ITokenPriceLoadingProps> = ({ icon, price, loading, onClick }) => {
    return (
        <Wrapper onClick={onClick}>
            <Icon src={icon}></Icon>
            {loading && <Price><LoadingSkeleton /></Price>}
            {!loading && <Price>{price}</Price>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-width: 90px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px 13px 10px 10px;
    border-radius: 20px;
    background-color: #282641;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    object-fit: contain;
    border-radius: 50%;
`
const Price = styled.div`
    font-size: 14px;
    color:#fff;
    flex:1;
`