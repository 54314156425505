import styled from "styled-components";
import ReactLoading from 'react-loading'

export const Loading = () => {
    return (
        <Wrapper>
            <Main>
                <ReactLoading color="#ffc100" type="spin"></ReactLoading>
            </Main>
        </Wrapper>
    )
}

export const ButtonLoading = () => {
    return (
        <ButtonWrapper>
            <ReactLoading width={35} height={35} color="#00d989" type="spin"></ReactLoading>
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled.div`
    margin-right: 15px;
`
const Wrapper = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    justify-content: center;
    display:flex;
    backdrop-filter: blur(1px);
    z-index: 20000;
    align-items: center;
`

const Main = styled.div`
    width:100px;
    height:100px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.7);
    z-index: 20001;
    justify-content: center;
    display:flex;
    align-items: center;
`