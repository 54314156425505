import React, { ReactNode, FC, ChangeEvent } from 'react'
import styled from 'styled-components'

/**
 * input 属性
 */
export interface InputProps {
    /** 值 */
    value: string,
    /** change 事件 */
    onChange: (value: string) => void,
    /** 图标前缀 */
    icon: string
    /** max 点击事件 */
    onMaxClick: () => void
    /** balanceof */
    balance?: ReactNode
    /** placeholder */
    placeholder: string
}

/** input 输入框 */
export const Input: FC<InputProps> = ({ icon, placeholder, onMaxClick, balance, value, onChange }) => {
    // 表单change
    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
            onChange(value)
        }
    }
    return (
        <Wrapper>
            <Left>
                <Icon src={icon}></Icon>
                <InputForm placeholder={placeholder} value={value} onChange={handleChangeValue} />
            </Left>
            <Right>
                {balance}
                <MaxButton onClick={onMaxClick}>Max</MaxButton>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    height: 60px;
    padding: 18px 20px;
    border-radius: 30px;
    background-color: #0b111c;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Left = styled.div`
    display: flex;
    align-items: center;
    flex:1;
`

const Icon = styled.img`
    width: 26px;
    height: 26px;
    margin-right: 15px;
`

const InputForm = styled.input`
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    &::placeholder{
        color: #6a6b87;
    }
`

const Right = styled.div`
     display: flex;
    align-items: center;
    margin-left: 10px;
`

const MaxButton = styled.div`
     width: 40px;
     height: 24px;
     outline: none;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 25px;
     border: solid 1px #3f3f55;
     background-color: transparent;
     cursor: pointer;
     font-size: 12px;
     color: #6a6b87;
     margin-left: 10px;
     user-select:none;
     -webkit-tap-highlight-color:transparent;
`