import styled from "styled-components";
import { FC, ChangeEvent } from 'react'
import { Search } from 'react-feather'

/** 属性 */
export interface ISearchProps {
    /** placeHolder */
    placeholder?: string
    /** 输入的值 */
    value: string
    /** onChange 事件 */
    onChange: (value: string) => void
}

/** 查询输入框 */
export const SearchInput: FC<ISearchProps> = ({ value, onChange, placeholder = "" }) => {

    /** 输入框onChange 事件 */
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        onChange(value)
    }
    return (
        <Wrapper>
            <Icon>
                <Search size={18} color="#7d7e9d"></Search>
            </Icon>
            <Input value={value} placeholder={placeholder} onChange={handleChange} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width:100%;
    height:100%;
    padding: 0px 0px 0px 15px;
    border-radius: 25px;
    background-color: #101124;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`
const Icon = styled.div`
    width: 18px;
    height:18px;
    margin-right: 15px;
`
const Input = styled.input`
    flex:1;
    border:none;
    outline: none;
    background: transparent;
    font-size: 14px;
    color: #a1a3c4;
    &::placeholder{
        color: #6a6b87;
    }
`